<template>
  <v-container
    id="my-profile"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Customize Media
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="4"
                  class="mediaLabel"
                >
                  Welcome Media
                </v-col>
                <v-col
                  cols="8"
                  class="manageMediaWrapper"
                >
                  <input
                    ref="videoFile"
                    type="file"
                    style="display: none"
                    @change="onWaitingMediaFileChange"
                  >
                  <video
                    v-if="previews.video"
                    :src="previews.video"
                    controls
                  />
                  <div
                    v-if="!previews.video"
                    class="uploadBox"
                    @click="$refs.videoFile.click()"
                  >
                    <img
                      src="https://icon-library.com/images/upload_video_162306_9899.png"
                      alt="alt"
                    >
                    <p>Upload video / audio</p>
                  </div>
                  <div
                    v-if="previews.video"
                    class="uploadActions"
                  >
                    <div
                      class="reUpload"
                      @click="$refs.videoFile.click()"
                    >
                      <v-icon>mdi-upload</v-icon> Upload another file
                    </div>
                    <div
                      v-if="files.video"
                      class="deleteBtn"
                      @click="onVideoFileClear"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col
                  cols="4"
                  class="mediaLabel"
                >
                  Welcome Audio
                </v-col>
                <v-col
                  cols="8"
                  class="manageMediaWrapper"
                >
                  <input
                    ref="audioFile"
                    type="file"
                    style="display: none"
                    accept=".mp3,audio/*"
                    @change="onAudioFileChange"
                  >
                  <audio
                    v-if="previews.audio"
                    :src="previews.audio"
                    controls
                  />
                  <div
                    v-if="!previews.audio"
                    class="uploadBox"
                    @click="$refs.audioFile.click()"
                  >
                    <img
                      src="https://cdn0.iconfinder.com/data/icons/music-audio-10/24/up-music-audio-playlist-arrow-upload-playlists-512.png"
                      alt="alt"
                    >
                    <p>Upload a custom audio</p>
                  </div>
                  <div
                    v-if="previews.audio"
                    class="uploadActions"
                  >
                    <div
                      class="reUpload"
                      @click="$refs.audioFile.click()"
                    >
                      <v-icon>mdi-upload</v-icon> Upload another file
                    </div>
                    <div
                      class="deleteBtn"
                      @click="onAudioFileClear"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col
                  cols="4"
                  class="mediaLabel"
                >
                  Logo
                </v-col>
                <v-col
                  cols="8"
                  class="manageMediaWrapper"
                >
                  <input
                    ref="imageFile"
                    accept="image/x-png,image/gif,image/jpeg"
                    type="file"
                    style="display: none"
                    @change="onImageFileChange"
                  >
                  <img
                    v-if="previews.logo"
                    :src="previews.logo"
                    alt="alt"
                    class="logoPreview"
                  >
                  <div
                    v-if="!previews.logo"
                    class="uploadBox"
                    @click="$refs.imageFile.click()"
                  >
                    <img
                      src="https://thebakersnest.in/wp-content/uploads/2019/10/img_234957.png"
                      alt="alt"
                    >
                    <p>Upload a custom logo</p>
                  </div>
                  <div
                    v-if="previews.logo"
                    class="uploadActions"
                  >
                    <div
                      class="reUpload"
                      @click="$refs.imageFile.click()"
                    >
                      <v-icon>mdi-upload</v-icon> Upload another file
                    </div>
                    <div
                      v-if="files.logo"
                      class="deleteBtn"
                      @click="onImageFileClear"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="files.logo || files.video"
              >
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="save"
                  >
                    Update
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import Vue from 'vue'
  import Clipboard from 'v-clipboard'

  Vue.use(Clipboard)

  export default {

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      snackbar: false,
      notification: 'Unknown',
      isOverlayVisible: false,
      username: '',
      twilioSid: '',
      twilioAuthToken: '',
      twilioSecret: '',
      isTwilioSidVisible: false,
      isTwilioAuthTokenVisible: false,
      isTwilioSecretVisible: false,
      clientId: '',
      clientSecret: '',
      isClientIdVisible: false,
      isClientSecretVisible: false,
      user: null,
      previews: {
        logo: null,
        audio: null,
        video: null,
      },
      files: {
        logo: null,
        audio: null,
        video: null,
      },
    }),

    async created () {
      this.usermail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async setUserData (profileResponse) {
        this.user = profileResponse
        this.username = profileResponse.Username
        this.twilioSid = profileResponse.TwilioSID
        this.twilioAuthToken = profileResponse.TwilioToken
        this.twilioSecret = profileResponse.TwilioSecret
        this.clientId = profileResponse.ClientID
        this.isClientIdVisible = true
      },
      async uploadFile (file, name) {
        try {
          const formData = new FormData()
          formData.append(name, file)
          const res = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}video-configs?code=${this.apiCode}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          return res.data
        } catch (error) {
          return ''
        }
      },
      async save () {
        this.isOverlayVisible = true
        // Upload files
        const files = []
        Object.keys(this.files).forEach(key => {
          if (this.files[key]) {
            let name = ''
            switch (key) {
              case 'logo': {
                name = 'video_logo'
                break
              }
              case 'audio': {
                name = 'wait_media'
                break
              }
              case 'video': {
                name = 'wait_media'
                break
              }
            }
            files.push({
              name,
              file: this.files[key],
            })
          }
        })
        if (files.length) {
          for (const file of files) {
            await this.uploadFile(file.file, file.name)
          }
          this.isOverlayVisible = false
          this.notification = 'Files updated successfully'
          this.snackbar = true
        } else {
          this.isOverlayVisible = false
          this.notification = 'Please select atleast one file to update'
          this.snackbar = true
        }
      },
      onCopy (textToCopy, textToShow) {
        this.$clipboard(textToCopy)
        this.notification = textToShow
        this.snackbar = true
      },
      async initialize () {
        try {
          this.isOverlayVisible = true
          const profileResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}userid?code=${this.apiCode}`)
          const videoPlatformConfigs = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}video-configs?code=${this.apiCode}`)
          if (videoPlatformConfigs.data && videoPlatformConfigs.data.VideoLogo) {
            this.previews.logo = videoPlatformConfigs.data.VideoLogo
          }
          if (videoPlatformConfigs.data && videoPlatformConfigs.data.WaitMedia) {
            this.previews.video = videoPlatformConfigs.data.WaitMedia
          }
          this.setUserData(profileResponse.data)
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },
      onImageFileChange (e) {
        const allowedFileTypes = ['image/jpeg',
                                  'image/gif',
                                  'image/png',
                                  'image/webp']
        const uploadedFile = e.target.files[0]
        if (allowedFileTypes.indexOf(uploadedFile.type) > -1) {
          this.files.logo = uploadedFile
          this.previews.logo = URL.createObjectURL(uploadedFile)
        } else {
          this.notification = 'Please select a valid image for logo'
          this.snackbar = true
        }
      },
      onWaitingMediaFileChange (e) {
        const allowedFileTypes = ['video/mpeg',
                                  'video/mp4',
                                  'video/webm',
                                  'audio/mpeg',
                                  'audio/mp3',
                                  'audio/webm']
        const uploadedFile = e.target.files[0]
        if (allowedFileTypes.indexOf(uploadedFile.type) > -1) {
          this.files.video = uploadedFile
          this.previews.video = URL.createObjectURL(uploadedFile)
        } else {
          this.notification = 'Please select a valid video / audio file'
          this.snackbar = true
        }
      },
      onImageFileClear () {
        this.previews.logo = null
        this.files.logo = null
      },
      onAudioFileClear () {
        this.previews.audio = null
      },
      onVideoFileClear () {
        this.previews.video = null
        this.files.video = null
      },
    },
  }

</script>

<style lang="scss" scoped>
    .mediaLabel {
      font-size: 1.4rem;
      padding: 18px;
    }
  .manageMediaWrapper {
    .uploadBox {
      width: 100%;
      height: 250px;
      background-color: rgba(230, 230, 230, 0.585);
      border: 1px solid rgb(173, 173, 173);
      border-radius: 8px;
      // box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;
      img {
        width: 110px;
        margin-bottom: 12px;
      }
      p {
        font-weight: 600;
      }
    }
    .uploadActions {
      display: flex;
      margin-top: 8px;
      cursor: pointer;
      margin-bottom: 18px;
      align-items: center;
      .reUpload {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        text-align: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: rgb(0, 148, 25);
        color: white;
        * {
          color: white;
        }
        flex: 7;
      }
      .deleteBtn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: rgb(177, 0, 0);
        color: white;
        * {
          color: white;
        }
        height: 40px;
      }
    }
    video {
            width: 100%;

    }
    audio {
            width: 100%;

    }
    .logoPreview {
            width: 100%;
    }
  }
</style>
